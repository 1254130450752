
import { UserModule } from '@/store/modules/user';
import { IPost, IBoard } from '@/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import { likePost } from '@/api/postLike';
import { parseKrw } from '@/filters';
import { getBestTagList } from '@/api/postTag';

@Component({
  name: '',
  components: {
    VueSlickCarousel,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private postList!: IPost[]

  @Prop({ required: true }) private listQuery!: any

  @Prop({ default: 0 }) private totalElements!: number

  @Prop({ required: true }) private board!: IBoard

  mounted() {
    this.init();
    this.getBestTagList();
  }

  private init() {
    if (this.$route.query) {
      this.search = {
        ...this.search,
        ...this.$route.query,
      };
    }
    if (this.board.fieldList) {
      this.searchFieldList = this.board.fieldList.filter((field: any) => {
        const searchField = field.searchState;
        return searchField;
      });
    }
  }

  private bestTagList = [];

  private searchFieldList: any[] = [];

  private commonApiUrl = process.env.VUE_APP_COMMON_API;

  private search: any = {
    searchType: 'titleOrContentOrTag',
    searchValue: '',
    categoryList: [],
  };

  private getPostNumber(index: number) {
    let totalSize = (this.listQuery.page - 1) * this.listQuery.size;
    if (totalSize < 0) totalSize = 0;
    return this.totalElements - totalSize - index;
  }

  private getWriteAuth() {
    const userRoles = UserModule.roles;
    if (this.board.authWrite === 'GUEST') return true;
    if (userRoles.indexOf('ROLE_ADMIN') > -1) return true;
    if (this.board.authWrite === 'MEMBER' && UserModule.isLogin) return true;
    return false;
  }

  private filterSearchType(type: string) {
    let rt = false;
    this.board.fieldList.forEach((flist: any) => {
      if (flist.fieldType.typeCode === type) rt = true;
    });
    return rt;
  }

  private handleSearch() {
    this.$emit('search', this.search);
  }

  private handleClickPost(post: any) {
    this.$router.push({ name: 'PostDetail', params: { boardUid: this.$route.params.boardUid, postUid: post.uid }, query: { ...this.listQuery } });
  }

  private handleClickNewPost() {
    this.$router.push({ name: 'PostAdd' });
  }

  private handleChangeCategory(categoryUid: any) {
    if (categoryUid) this.search.categoryList = [categoryUid];
    else this.search.categoryList = [];
    this.handleSearch();
  }

  private handleLikePost(post: any) {
    if (UserModule.isLogin) {
      likePost(post.uid).then(() => {
        post.likeStatus = !post.likeStatus;
        if (post.likeStatus) post.likeCount += 1;
        else post.likeCount -= 1;
      });
    } else {
      this.$router.push({ name: 'Login' });
    }
  }

  private handleClickTag(tag: any) {
    this.search.searchValue = `#${tag}`;
    this.handleSearch();
  }

  private getBestTagList() {
    getBestTagList().then((res) => {
      this.bestTagList = res.data;
    });
  }
}

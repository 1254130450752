
import { getPostList } from '@/api/post';
import { parseKrw } from '@/filters';
import { UserModule } from '@/store/modules/user';
import { IPost, IBoard } from '@/types';
import { Pagination } from 'element-ui';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'BoardPhotoList',
})
export default class extends Vue {
  @Prop({ required: true }) private postList!: IPost[]

  @Prop({ required: true }) private listQuery!: any

  @Prop({ default: 0 }) private totalElements!: number

  @Prop({ required: true }) private board!: IBoard

  mounted() {
    this.init();
    this.getHotPostList({});
  }

  private hotPostList: any = [];

  private init() {
    if (this.$route.query) {
      this.search = {
        ...this.search,
        ...this.$route.query,
      };
    }
    if (this.board.fieldList) {
      this.searchFieldList = this.board.fieldList.filter((field: any) => {
        const searchField = field.searchState;
        return searchField;
      });
    }
  }

  private searchFieldList: any[] = [];

  private search: any = {
    searchType: 'title',
    searchValue: '',
    categoryList: [],
  };

  private getPostNumber(index: number) {
    let totalSize = (this.listQuery.page - 1) * this.listQuery.size;
    if (totalSize < 0) totalSize = 0;
    return this.totalElements - totalSize - index;
  }

  private getWriteAuth() {
    const userRoles = UserModule.roles;
    if (this.board.authWrite === 'GUEST') return true;
    if (userRoles.indexOf('ROLE_ADMIN') > -1) return true;
    if (this.board.authWrite === 'MEMBER' && UserModule.isLogin) return true;
    return false;
  }

  private filterSearchType(type: string) {
    let rt = false;
    this.board.fieldList.forEach((flist: any) => {
      if (flist.fieldType.typeCode === type) rt = true;
    });
    return rt;
  }

  private handleSearch() {
    this.$emit('search', this.search);
  }

  private handleClickPost(post: any) {
    this.$router.push({
      name: 'PostDetail',
      params: {
        boardUid: this.$route.params.boardUid,
        postUid: post.uid,
      },
      query: {
        ...this.listQuery,
      },
    }).catch(() => {
      // do nothing.
    });
  }

  private handleClickCategory(categoryUid: any) {
    if (categoryUid) {
      this.search.categoryList = [categoryUid];
    } else {
      this.search.categoryList = [];
    }
    this.$emit('search', this.search);
    this.getHotPostList(this.search);
  }

  private getHotPostList(search: any) {
    const listQuery: any = {
      ...this.listQuery,
      page: 1,
      size: 3,
      sort: 'hot',
    };
    if (search && search.categoryList && search.categoryList.length > 0) {
      listQuery.categoryList = search.categoryList[0];
    } else {
      listQuery.categoryList = '';
    }
    getPostList(listQuery).then((res) => {
      this.hotPostList = res.data.content;
    });
  }
}
